import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import useFilters from '../../../../lib/use_filters';
import useI18n from '../../../../lib/use_i18n';
import { useIsVisible } from '../../../../lib/use_is_visible';
import useQuery from '../../../../lib/use_query';
import useVisibility from '../../../../lib/use_visibility';
import Icon from '../../icon';
import InlineConfirmation from '../../inline_confirmation';
import SearchTerm from '../../search_term';
import { MODAL_WRAPPER_ID } from '../../modal/modal';
import { BorderlessButton } from '../../styles/borderless_button';
import { ButtonsWrapper, Flex } from '../../styles/flex';
import { Span } from '../../styles/text';
import { borderLightGray, fontSizeXS, gray } from '../../styles/variables';
import { Field } from '../field';
import { RenameInputWrapper, StyledImageRow } from './styles';

const Img = styled.img`
  width: auto;
  max-width: fit-content;
  border: 1px solid ${borderLightGray};
  border-radius: 3px;
`;

const ImageName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
`;

const Thumbnail = styled.img`
  max-height: 30px;
  min-width: 40px;
  width: 40px;
`;

const NoImagesWrapper = styled.div`
  * {
    font-size: ${fontSizeXS};
  }
`;

export const SelectImage = ({
  close,
  handleDelete,
  handleRename,
  handleSelect,
  setActiveTab,
  selectedImageUrl,
  fetchImages,
}) => {
  const listRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const { data: images = [], setData: setImages } = useQuery(
    'mediaAssets',
    fetchImages
  );
  const {
    items,
    filters: { search },
  } = useFilters(
    images,
    {},
    { order: { by: 'name', ascending: true } },
    ['name'],
    { minMatchCharLength: 1 }
  );
  const { translate } = useI18n('image_uploader.select_image');

  const deleteImage = async (image) => {
    handleDelete && (await handleDelete(image.id));

    // remove deleted image from displayed images list
    setImages(items.filter((img) => img.url !== image.url));

    // if we are deleting the selected image,
    // fallback to the fitsr image
    if (image.url === selectedImage.url) {
      const newSelectedImage = items.find((img) => img.url !== image.url);
      setSelectedImage(newSelectedImage);
    }
  };

  // this is called after the handleRename callback. once we are sure that
  // the image is renamed successfully, we want to update image list(localImages)
  // and selectedImage to reflect those changes
  const updateImageList = (image) => {
    setSelectedImage(image);
    setImages((images) =>
      images.map((oldImage) => (oldImage.id === image.id ? image : oldImage))
    );
  };

  const generateHandleUpdate = (hideNameInput) => async (image) => {
    try {
      await handleRename(image);
      updateImageList(image);
      hideNameInput();
    } catch (error) {
      return error?.response?.data;
    }
  };

  // automatically set selecetd image if selectedImage is falsy
  // e.g. after fetching the images for the first time.
  // the selected image will be picked based on the form url value,
  // before it falls back to the first image in list
  useEffect(() => {
    if (items.length && !selectedImage) {
      const newSelectedImage =
        items.find(({ url }) => selectedImageUrl === url) || items[0];
      setSelectedImage(newSelectedImage);
    }
  }, [items, Boolean(selectedImage), setSelectedImage, selectedImageUrl]); //eslint-disable-line

  return images?.length ? (
    <Flex flexGrow="1" gap={'16px'} height={'100%'} flexDirection="column">
      <SearchTerm
        for="image_uploader.select_image"
        id="images-input-search"
        searchTerm={search.state}
        onChange={search.setState}
      />
      <Flex flexGrow="1" overflow="hidden" gap="8px" height="100%">
        <Flex
          ref={listRef}
          data-testid="image-list"
          overflow="auto"
          width="50%"
          flexDirection="column"
          max-height="350px"
        >
          <Span marginBottom="8px" fontSize={fontSizeXS} color={gray}>
            {translate('.images', {
              count: items.length,
              totalCount: images.length,
            })}
          </Span>
          {items.map((image) => (
            <ImageRow
              listRef={listRef}
              key={image.url}
              active={selectedImage?.url === image.url}
              image={image}
              onClick={setSelectedImage}
              generateHandleUpdate={generateHandleUpdate}
              handleDelete={deleteImage}
              onDoubleClick={(image) => {
                handleSelect(image);
                close();
              }}
            />
          ))}
        </Flex>
        <Flex width="50%" flexDirection="column" gap="8px">
          <Span fontSize={fontSizeXS} color={gray}>
            {translate('app.actions.preview')}
          </Span>
          <Img
            data-testid="preview-image"
            src={selectedImage?.url}
            alt={selectedImage?.name}
          />
          <Span fontWeight="bold" marginTop="auto">
            {selectedImage?.name}
          </Span>
        </Flex>
      </Flex>
      <ButtonsWrapper marginTop="auto">
        <button onClick={close} className="btn btn-default">
          {translate('app.actions.cancel')}
        </button>
        <button
          onClick={() => {
            handleSelect(selectedImage);
            close();
          }}
          className="btn btn-primary"
        >
          {translate('app.actions.select')}
        </button>
      </ButtonsWrapper>
    </Flex>
  ) : (
    <NoImagesWrapper>
      <Span color={gray}>{translate('.no_images')}</Span>
      <BorderlessButton onClick={() => setActiveTab('upload')}>
        {translate('.upload_image')}
      </BorderlessButton>
    </NoImagesWrapper>
  );
};

const ImageRow = ({
  onClick,
  handleDelete,
  generateHandleUpdate,
  image,
  active,
  listRef,
  onDoubleClick,
}) => {
  const { translate } = useI18n('image_uploader.select_image');
  const rowRef = useRef();
  const isVisible = useIsVisible(rowRef, listRef);
  const {
    shown: isRenaming,
    show: showNameInput,
    hide: hideNameInput,
  } = useVisibility(rowRef, false, true, `#${MODAL_WRAPPER_ID}`);

  const handleUpdate = generateHandleUpdate(hideNameInput);

  return (
    <StyledImageRow
      ref={rowRef}
      role="listitem"
      active={!isRenaming && active}
      isRenaming={isRenaming}
      onClick={() => onClick(image)}
      onDoubleClick={!isRenaming ? () => onDoubleClick(image) : undefined}
    >
      {isVisible ? (
        isRenaming ? (
          <RenameInput image={image} handleSubmit={handleUpdate} />
        ) : (
          <>
            <Flex overflow="hidden" gap="8px" alignItems="center">
              <Thumbnail src={image.url} alt={image.name} />
              <ImageName>{image.name}</ImageName>
            </Flex>
            <Flex>
              <Button
                data-balloon-pos="left"
                aria-label={translate('.edit_tooltip')}
                onClick={() => showNameInput()}
              >
                <Icon name="edit" size={24} />
              </Button>
              <InlineConfirmation
                confirmClass="btn-danger"
                onConfirmation={() => handleDelete(image)}
              >
                {(deleteCallback) => (
                  <Button
                    data-balloon-pos="left"
                    aria-label={translate('app.actions.delete')}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteCallback();
                    }}
                  >
                    <Icon name="delete" size={24} />
                  </Button>
                )}
              </InlineConfirmation>
            </Flex>
          </>
        )
      ) : null}
    </StyledImageRow>
  );
};

const RenameInput = ({ image, handleSubmit }) => {
  const { translate } = useI18n();
  return (
    <Form onSubmit={handleSubmit} initialValues={image}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <RenameInputWrapper
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Field name="name">
              {({ input }) => (
                <input
                  autoFocus={true} // eslint-disable-line
                  {...input}
                  onFocus={(e) => e.target.select()}
                />
              )}
            </Field>
            <BorderlessButton type="submit">
              {translate('app.actions.save')}
            </BorderlessButton>
          </RenameInputWrapper>
        </form>
      )}
    </Form>
  );
};
