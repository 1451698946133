import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useI18n from '../../../../lib/use_i18n';
import Icon from '../../icon';
import { ProgressBar } from '../../progress_bar';
import { Flex } from '../../styles/flex';
import { ErrorWrapper } from '../../styles/form';
import { gray } from '../../styles/variables';
import {
  BrowseButtonContainer,
  ButtonsWrapper,
  ContainerWithSelectedImage,
  ImageUploadContainer,
} from './styles';
import { fileValidator, humanFileSize } from './utils';

export const UploadImage = ({
  close,
  formatHint,
  handleSelect,
  handleUpload,
  maxFileSize,
  maxWidth,
  minWidth,
  successMsg,
  uploadErrorMsg,
  validFileFormats,
}) => {
  const { translate } = useI18n('image_uploader');
  const [fileError, setFileError] = useState(null);
  const [file, setFile] = useState();
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: async (files) => {
      setFileError(null);
      const [fileAdded] = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFile(fileAdded);
      setFileError(
        await fileValidator({
          file: fileAdded,
          maxFileSize,
          maxWidth,
          minWidth,
          translate,
          validFileFormats,
        })
      );
    },
  });

  return !file ? (
    <DropZone
      validFileFormats={validFileFormats}
      formatHint={formatHint}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      maxFileSize={maxFileSize}
      open={open}
    />
  ) : (
    <ImageUpload
      file={file}
      handleUpload={handleUpload}
      setFile={setFile}
      handleSelect={handleSelect}
      uploadErrorMsg={uploadErrorMsg}
      fileError={fileError}
      setFileError={setFileError}
      close={close}
      successMsg={successMsg}
    />
  );
};

const DropZone = ({
  validFileFormats,
  formatHint,
  getInputProps,
  getRootProps,
  maxFileSize,
  open,
}) => {
  const { translate } = useI18n('image_uploader');
  return (
    <ImageUploadContainer {...getRootProps()}>
      <input
        {...getInputProps()}
        name="image_upload_modal_input"
        data-testid="drop-input"
      />

      <div>
        <Icon name="image" size={24} />

        <div>
          {translate('.format_and_size', {
            size: humanFileSize(maxFileSize, 'mb'),
          })}
        </div>
        <div>{formatHint}</div>
        <div>
          {validFileFormats
            ?.map((fileExtension) => fileExtension.toUpperCase())
            .join(', ')}
        </div>

        <BrowseButtonContainer>
          <button type="button" className="btn btn-default" onClick={open}>
            {translate('.buttons.browse')}
          </button>
        </BrowseButtonContainer>
        <div>{translate('.drag_and_drop')}</div>
      </div>
    </ImageUploadContainer>
  );
};

const ImageUpload = ({
  file,
  handleUpload,
  setFile,
  handleSelect,
  uploadErrorMsg,
  fileError,
  setFileError,
  successMsg,
  close,
}) => {
  const { translate } = useI18n('image_uploader');
  const [uploadError, setUploadError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [requestController, setRequestController] = useState(null);
  const [progress, setProgress] = useState(0);
  const reset = () => {
    setProgress(0);
    setUploading(false);
    setFileError(null);
    setUploadError(null);
    setFile(null);
  };

  const onUpload = ({ url } = {}) => {
    reset();
    handleSelect({ url, name: file.name });
    Flash.success(successMsg);
    close();
  };

  const onUploadProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const onError = () => {
    setUploadError(uploadErrorMsg);
    setUploading(false);
  };

  const upload = async () => {
    setUploading(true);
    const abortController = new AbortController();
    setRequestController(abortController);
    const signal = abortController.signal;
    await handleUpload({
      file,
      onUploadProgress,
      onSuccess: onUpload,
      signal,
      onError,
    });
  };
  return (
    <ContainerWithSelectedImage>
      <Flex
        overflowY="auto"
        flexDirection="column"
        padding="16px"
        marginBottom="56px"
      >
        {!fileError && (
          <img
            src={file.preview}
            className="img-thumbnail"
            data-testid="image-preview"
            alt={file.name}
          />
        )}
        <strong>{file.name}</strong>
        <Flex color={gray}>{humanFileSize(file.size, 'kb')} KB</Flex>

        {(fileError || uploadError) && (
          <ErrorWrapper>{fileError || uploadError}</ErrorWrapper>
        )}

        {!uploading ? (
          <Flex gap="10px" position="absolute" bottom="16px" right="16px">
            <button type="button" className="btn btn-default" onClick={reset}>
              {translate('.buttons.discard')}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={fileError}
              onClick={upload}
            >
              {translate('.buttons.upload')}
            </button>
          </Flex>
        ) : (
          <Uploading
            progress={progress}
            onCancel={() => {
              setProgress(0);
              setUploading(false);
              requestController.abort();
            }}
          />
        )}
      </Flex>
    </ContainerWithSelectedImage>
  );
};

const Uploading = ({ progress, onCancel }) => {
  const { translate } = useI18n('image_uploader');
  return (
    <div>
      <Flex alignItems="center" gap={'16px'}>
        <ProgressBar percentage={progress} />
        <div className="progress-label">{progress}%</div>
      </Flex>

      <ButtonsWrapper>
        <button
          type="button"
          className="btn btn-default"
          data-testid="cancel-button"
          onClick={onCancel}
        >
          {translate('.buttons.cancel')}
        </button>
      </ButtonsWrapper>
    </div>
  );
};
