import React, { useCallback } from 'react';
import useI18n from '../../../../lib/use_i18n';
import { Tabs } from '../../tabs';
import { SelectImage } from './select_image';
import { UploadImage } from './upload_image';

export const ImageUploadModal = ({
  Modal,
  allowSelect,
  close,
  fetchImages,
  formatHint,
  handleDelete,
  handleRename,
  handleSelect,
  handleUpload,
  images,
  selectedImageUrl,
  maxFileSize,
  maxWidth,
  minWidth,
  modalTitle,
  successMsg,
  uploadErrorMsg,
  validFileFormats,
}) => {
  const { translate } = useI18n('image_uploader');
  const UploadImageWithProps = useCallback(
    () => (
      <UploadImage
        close={close}
        formatHint={formatHint}
        handleSelect={handleSelect}
        handleUpload={handleUpload}
        maxFileSize={maxFileSize}
        maxWidth={maxWidth}
        minWidth={minWidth}
        successMsg={successMsg}
        uploadErrorMsg={uploadErrorMsg}
        validFileFormats={validFileFormats}
      />
    ),
    [
      close,
      formatHint,
      handleSelect,
      handleUpload,
      maxFileSize,
      maxWidth,
      minWidth,
      successMsg,
      uploadErrorMsg,
      validFileFormats,
    ]
  );
  return (
    <Modal
      style={{ minHeight: '550px' }}
      width="80vw"
      height="80vh"
      id="imageUploader-modal"
      title={modalTitle}
    >
      {allowSelect ? (
        <Tabs
          bodyStyle={{ overflow: 'hidden', height: '100%' }}
          tabs={[
            {
              name: 'select',
              label: translate('.select_tab'),
              component: ({ setActiveTab }) => (
                <SelectImage
                  close={close}
                  fetchImages={fetchImages}
                  handleDelete={handleDelete}
                  handleRename={handleRename}
                  handleSelect={handleSelect}
                  images={images}
                  selectedImageUrl={selectedImageUrl}
                  setActiveTab={setActiveTab}
                />
              ),
            },
            {
              name: 'upload',
              label: translate('.upload_tab'),
              component: UploadImageWithProps,
            },
          ]}
        />
      ) : (
        <UploadImageWithProps />
      )}
    </Modal>
  );
};
