import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from './icon';
import useI18n from '../../lib/use_i18n';

const Wrapper = styled.div`
  display: inline-flex;
  min-width: 140px;
  gap: 4px;
  height: 32px;
  .material-symbols-outlined {
    line-height: 1.5;
  }
`;

/**
 * Renders a confirm and cancel buttons. Once confirmed, both buttons get disabled.
 */
export const Btns = ({
  onConfirmation,
  onCancel,
  hide,
  action,
  btnClass,
  confirmClass,
  cancelClass,
}) => {
  btnClass = btnClass || 'btn-sm';

  const { translate } = useI18n('app.actions');

  const [disabled, setDisabled] = useState(false);

  const handleConfirmation = (e) => {
    e.stopPropagation();
    setDisabled(true);
    onConfirmation(hide, action);
  };

  return (
    <Wrapper>
      <button
        onClick={handleConfirmation}
        className={`btn ${btnClass} ${confirmClass}`}
        disabled={disabled}
      >
        {translate('.confirm_decision')}
      </button>
      <button
        onClick={onCancel}
        title={translate('.cancel_deletion')}
        className={`btn btn-default ${btnClass} ${cancelClass}`}
        disabled={disabled}
      >
        <Icon name="close" />
      </button>
    </Wrapper>
  );
};

Btns.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  btnClass: PropTypes.string,
  confirmClass: PropTypes.string,
  cancelClass: PropTypes.string,
};

/**
 * Shows a confirmation and cancel buttons after clicking on a button (or link) which is provided
 * via a children prop.
 *
 *    <InlineConfirmation onConfirmation={ handleConfirmation }>
 *      { (clickHandler) => <a onClick={ clickHandler }>Delete</a> }
 *    </InlineConfirmation
 *
 * Optionally, the click handler passed to the children prop accepts an action which will be passed
 * as the second argument to the given onConfirmation callback. The first argument is a function
 * which can be called to hide the control buttons of this component.
 *
 *    const handleConfirmation = (hide, action) => { };
 */
const InlineConfirmation = (props) => {
  const [action, setAction] = useState(null),
    hide = (e) => {
      e?.stopPropagation();
      setAction(null);
    };

  if (action)
    return <Btns onCancel={hide} action={action} hide={hide} {...props} />;

  return props.children((action = 'none') => setAction(action));
};

InlineConfirmation.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
  btnClass: PropTypes.string,
  confirmClass: PropTypes.string,
  cancelClass: PropTypes.string,
};

export default InlineConfirmation;
